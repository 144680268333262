import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import TermsConditions from "../partials/TermsConditions"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"
import PromoHero from "../partials/PromoHero"
import { LINKS } from "../constants"
import "../styles.css"

const BarrecorePage: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Barrecore | Run For Heroes</title>
        <meta name="description" content="Barrecore" />
        <html lang="en" />
      </Helmet>
      <Nav />
      <PageSection backgroundColor={"BLUE"}>
        <PromoHero
          promoKey={"barrecore"}
          heading={"£5 off 5 Live Streamed classes"}
          body={
            <>
              <p>To redeem your offer, you must:</p>
              <p>
                1. Create an account at{" "}
                <a target={"_blank"} rel={"noopener"} href={LINKS.BARRECORE}>
                  www.barrecore.com
                </a>
                .
              </p>
              <p>2. Save a card on file.</p>
              <p>
                3. Email{" "}
                <a href={"mailto:hello@barrecore.com"}>hello@barrecore.com</a>{" "}
                quoting '5k May special offer - purchase 5 live streamed
                classes' with a proof of donation to the 5k May campaign.
              </p>
            </>
          }
        />
      </PageSection>
      <PageSection backgroundColor={"WHITE"}>
        <TermsConditions />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default BarrecorePage
